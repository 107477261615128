<template>
    <main>
        <page-header-compact>
            Traslado de Vacunas
        </page-header-compact>
        <div class="container-fluid">
            <div class="card mt-4">
                <div class="card-body">
                    <data-grid :data="pagination"
                           @changePage="changePage($event)"
                           @perPageChange="perPageChange($event)"
                           @search="search($event)">
                            <template #grid>
                                <grid-table>
                                    <template #head>
                                        <grid-cell-header class="bg-light"  v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                                    </template>
                                    <template #body>
                                        <grid-row v-for="(item, i) in pagination.data" :key="`d_${i}`">
                                            <grid-cell class="text-center small">
                                                {{item.fecha}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                               <span v-if="item.sede_destino"> {{item.sede_destino.NOMBRE_SEDE}}</span>
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.user.name}}
                                            </grid-cell>
                                            
                                            <grid-cell class="text-center   ">
                                                <button class="btn btn-outline-info btn-xs mx-2" type="button" @click="imprimir(item.id)"  >
                                                      <i class="fas fa-receipt fa-fw" ></i> Imprimir
                                                </button>
                                            </grid-cell>
                                        </grid-row>
                                    </template>
                                </grid-table>
                            </template>
                        <template #action-button>
                            <button  class="btn btn-info btn-sm shadow-sm mb-2" type="button" @click="newEgreso();">
                                Nuevo &nbsp;<i class="fas fa-plus"></i>
                            </button>
                        </template>
                    </data-grid>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";

import DataGrid from "../../../components/common/utilities/DataGrid/DataGrid";
import GridTable from "../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../components/common/utilities/DataGrid/GridCellHeader";
import GridRow from "../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../components/common/utilities/DataGrid/GridCell";
import Toast from '../../../components/common/utilities/toast';
import trasladoVacunaService from '../../../services/trasladoVacunaService';

export default {
    components: {
        PageHeaderCompact,GridCell, GridRow, GridCellHeader, GridTable, DataGrid
    },

    data() {
        return {
            headers : [
               'Fecha',
               'Sede',
               'Usuario',
               'Opciones'
           ],
           pagination : {
               data : [],
               current_page : 1,
               page : 1,
               per_page : 5,
               search : '',
               total: '',
           }
        }
    },
    methods : {
        async getTraslados(){
            try {
                this.LoaderSpinnerShow();
                const response = (await trasladoVacunaService.index(this.pagination.search, this.pagination))?.data;
                this.pagination.data = response.data;
                this.pagination.current_page  = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();
            }catch (e) {
                console.error(e);
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        changePage(page) {
            this.pagination.page = page;
            this.getTraslados();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getTraslados();
        },
        search(query){
            this.pagination.search = query;
            this.pagination.page = 1;
            this.getTraslados();
        },
        newEgreso(){
            this.$router.push({name: "traslado.vacunas.create"});
        },
        imprimir(id){
            window.open(trasladoVacunaService.imprimir(id));
        }
        
    },
    async created() {
       await this.getTraslados();
    }

}
</script>
